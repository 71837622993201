<template>
<div class="header">
  <img
      src="@/assets/images/logo.svg"
      alt="logo"
      class="header__logo"
      width="67"
      height="56">
</div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
.header {
  padding-top: 28px;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .header__logo {
    display: none;
  }
}
</style>
